<template>
  <div v-if="this.pag !== 'home'">
    <v-navigation-drawer style="position: fixed"
        v-model="drawer" absolute expand-on-hover permanent>
      <v-list nav dense >
        <v-list-item-group v-model="group" active-class="blue">

          <v-list-item class="mt-6" to='/home'>
            <v-icon>mdi-home</v-icon>
            <v-list-item-title class="pl-2">Página Inicial</v-list-item-title>
          </v-list-item>

          <v-list-item class="mt-6" to="/examesAbertos">
            <v-icon>mdi mdi-book-open-blank-variant-outline</v-icon>
            <v-list-item-title class="pl-2">Exames em aberto</v-list-item-title>
          </v-list-item>

          <v-list-item class="mt-6" to="/tempoDeExamesPorSetor">
            <v-icon>mdi-alarm</v-icon>
            <v-list-item-title class="pl-2">Tempo por setor</v-list-item-title>
          </v-list-item>

          <v-list-item class="mt-6" to="/conclusao">
            <v-icon>mdi-check</v-icon>
            <v-list-item-title class="pl-2">Conclusão</v-list-item-title>
          </v-list-item>

          <v-list-item class="mt-6" to="/numeroExamesLiberados">
            <v-icon>mdi-calendar-month</v-icon>
            <v-list-item-title class="pl-2">Evolução mensal</v-list-item-title>
          </v-list-item>

          <v-list-item class="mt-6" to="/evolucaoDoNumeroDeExamesPorPatologista">
            <v-icon>mdi-account</v-icon>
            <v-list-item-title class="pl-2">Evolução por patologista</v-list-item-title>
          </v-list-item>

          <v-list-item class="mt-6" to="/examesEncerrados">
            <v-icon>mdi-check-all</v-icon>
            <v-list-item-title class="pl-2">Encerrados</v-list-item-title>
          </v-list-item>

          <v-list-item class="mt-6" to="/examesEncerradosClassificador">
            <v-icon>mdi-script-text-outline</v-icon>
            <v-list-item-title class="pl-2">Encerrados - Classificador</v-list-item-title>
          </v-list-item>

          <v-list-item class="mt-6" to="/tempoDeEntrega">
            <v-icon>mdi-clock-fast</v-icon>
            <v-list-item-title class="pl-2">Tempo de entrega</v-list-item-title>
          </v-list-item>

          <v-divider class="mt-8"></v-divider>

          <v-list-item class="mt-8" @click="confirmarSair()">
            <v-icon>mdi-logout</v-icon>
            <v-list-item-title class="pl-2">Sair do sistema</v-list-item-title>
          </v-list-item>

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
// import { Slide } from 'vue-burger-menu'
import vuetify from "@/plugins/vuetify";
import Swal from "sweetalert2";
import swal from "sweetalert2";

export default {
  name: "Menu",
  computed: {
  },
  vuetify,
  // components: { Slide },
  data: () => ({
    drawer: false,
    group: null,
    selectedItem: 1,
    pag: null,
  }),

  watch: {
    group () {
      this.drawer = false
    },
  },

  mounted() {
    this.verUrl()
  },

  methods: {
    confirmarSair() {
      swal.fire({
        title: 'Deseja mesmo sair?',
        text: "",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim'
      }).then((result) => {
        if (result.isConfirmed) {
          this.logout();
        }
      })
    },
    async logout() {
      try {
        localStorage.removeItem('token');
        localStorage.removeItem('selectedCNPJs');
        this.$router.push('/')
      } catch (err) {
        console.log(err)
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          timer: 3000,
          timerProgressBar: true,
          text: 'Falha na conexão com o servidor',
        });
      }
    },
    verUrl() {
      let url = window.location.href
      let partes = url.split("/");
      this.pag = partes[partes.length - 1]
    }
  }

}
</script>

<style scoped>
</style>